import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  CircularProgress,
  FormControl,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import * as Sentry from '@sentry/nextjs'
import { signIn } from 'next-auth/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useFormContext, UseFormProps } from 'react-hook-form'
import * as yup from 'yup'

import { externalRoutes } from 'external-routes'
import { routes } from 'routes'

import { checkDbError, GenericDbError } from 'graphql/error-handlers'

import ExternalLink from 'components/ExternalLink'
import ControlCheckBox from 'components/form/ControlCheckBox'
import ControlPasswordField from 'components/form/ControlPasswordField'
import ControlTextField from 'components/form/ControlTextField'
import FieldStack from 'components/form/FieldStack'
import { LanguageSwitcher } from 'components/layouts/nav/LanguageSwitcher'
import SubmitFormButton from 'components/SubmitFormButton'
import { useToast } from 'components/toast/ToastProvider'
import useGTM, { GtmEvent } from 'hooks/useGTM'

export type LoginFormData = {
  email: string
  password: string
  rememberMe: boolean
}

const schema = yup.object().shape({
  email: yup.string().trim().lowercase().email('Invalid email').required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(4, 'Password is too short')
    .max(100, 'Password is too long'),
  rememberMe: yup.boolean().optional(),
})
export const formOptions: UseFormProps<LoginFormData> = {
  mode: 'onBlur',
  defaultValues: { email: '', password: '', rememberMe: true },
  resolver: yupResolver(schema),
}

export default function LoginForm() {
  const router = useRouter()
  const { trackEvent } = useGTM()
  const { addToast } = useToast()
  const [loading, setLoading] = useState(false)

  const { handleSubmit } = useFormContext<LoginFormData>()

  const onSubmit = handleSubmit(async (values, event) => {
    event?.preventDefault()

    const id = addToast({ color: 'info', message: `Loading...` })
    setLoading(true)
    try {
      const signInResult = await signIn<'credentials'>('credentials', {
        email: values.email,
        password: values.password,
        callbackUrl:
          typeof router.query.callbackUrl === 'string' ? router.query.callbackUrl : undefined,
        redirect: false,
      })
      if (signInResult?.error) {
        throw new Error(signInResult.error)
      }

      // allow our tests to bypass the v1 login flow
      if (typeof router.query.v1Skip === 'string' && router.query.v1Skip === 'yes') {
        router.push(routes.root)
      } else {
        try {
          const secretResult = await fetch(routes.api.auth.generateSecret, {
            method: 'POST',
          })
          const jsonSecretResult = await secretResult.json()

          if (jsonSecretResult.userEmail && jsonSecretResult.hashedSecret) {
            const hasValidSignInResultUrl =
              signInResult?.url && !signInResult.url.includes(routes.login)
            const redirectUrl = hasValidSignInResultUrl ? signInResult.url : ''

            const fullRedirectUrl = `${externalRoutes.v1.magicLink}?e=${encodeURIComponent(
              jsonSecretResult.userEmail
            )}&s=${encodeURIComponent(jsonSecretResult.hashedSecret)}&u=${encodeURIComponent(
              redirectUrl || ''
            )}`

            router.push(fullRedirectUrl)
          }
        } catch (error) {
          setLoading(false)
          if (!(error instanceof Error)) {
            throw error
          }
        }
      }
    } catch (error) {
      setLoading(false)
      if (!(error instanceof Error)) {
        throw error
      }
      if (
        checkDbError(GenericDbError.LoginWithPasswordSSOUser, error) ||
        checkDbError(GenericDbError.LoginWithPasswordNoPasswordSet, error) ||
        checkDbError(GenericDbError.LoginWithSuspendedUser, error)
      ) {
        addToast({
          color: 'error',
          message: error.message,
          id,
        })
      } else {
        const badLogin = error.message === 'CredentialsSignin'
        if (!badLogin) {
          console.error(error)
          Sentry.captureException(error, { user: { email: values.email } })
        }
        trackEvent({ event: GtmEvent.LoginError })
        addToast({
          color: 'error',
          message: badLogin
            ? `Please check your credentials and try again`
            : `Currently we're having trouble signing you in.`,
          id,
        })
      }
    }
  })

  return (
    <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl disabled={loading}>
        <FieldStack spacing={2.5}>
          <Typography variant="h3" textAlign="left" color="primary">
            Sign In
          </Typography>
          <ControlTextField
            autoFocus
            id="email"
            name="email"
            type="email"
            label="Email"
            disabled={loading}
          />
          <ControlPasswordField name="password" label="Password" disabled={loading} />
          {loading ? (
            <Button variant="contained-secondary" size="large" color="secondary" disabled>
              <CircularProgress size={20} sx={{ color: 'primary.light', marginRight: '10px' }} />
              Loading...
            </Button>
          ) : (
            <SubmitFormButton label="Sign in" size="large" disabled={loading} />
          )}
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <ControlCheckBox name="rememberMe" label="Remember me" />
            <MuiLink
              component={NextLink}
              href={routes.forgotPassword}
              underline="hover"
              fontWeight={400}
              fontSize="small">
              Forgot password?
            </MuiLink>
          </Stack>
          <Typography color="text.primary" textAlign="left" fontWeight={300}>
            New to MakerHQ?{' '}
            <ExternalLink
              href="https://makerconnect.com/"
              color="secondary"
              underline="hover"
              fontWeight={400}
              fontSize="small">
              Learn more
            </ExternalLink>
          </Typography>
        </FieldStack>
        <Box display="flex" flex={1} alignItems="center" justifyContent={'center'} mt={4}>
          <Box sx={{ width: '200px' }}>
            <LanguageSwitcher />
          </Box>
        </Box>
      </FormControl>
    </Box>
  )
}
