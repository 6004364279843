import axios from 'axios'
import { useSession } from 'next-auth/react'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { destroyCookie, parseCookies, setCookie } from 'nookies'
import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react'

import { cookieDomain } from 'external-routes'

import { useFeatureFlag } from 'hooks/useFeatureFlags'

import { useUser } from './UserProvider'

// zendesk TS definitions taken from: https://gist.github.com/vsaarinen/b0e2dcaf4f7c522d4ff1f672eb996f73
type ZendeskChatContextType = {
  zendeskChatLoaded: boolean
}

interface ZendeskWidget {
  /**
   * If your application has a login flow, or if a user needs to access the same conversation from multiple devices,
   * you can use the `loginUser` API.
   *
   * You can associate users with your own user directory by issuing a `JWT` credential during the login flow.
   * For information on creating signing keys, see
   * [Authenticating end users in messaging](https://support.zendesk.com/hc/en-us/articles/4411666638746).
   * For information on creating JWT tokens, see
   * [Enabling authenticated visitors for messaging with Zendesk SDKs](https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/enabling_auth_visitors).
   *
   * ## Expiring JWTs
   * If you want to generate credentials that expire after a certain amount of time, using JWTs is a good way to
   * accomplish this.
   *
   * The `exp` (expiration time) property of a JWT payload is honored by the messaging Web Widget.
   * A request made with a JWT which has an `exp` that is in the past is rejected.
   *
   * Keep in mind that using JWTs with `exp` means you need to handle regeneration of JWTs in the function that you
   * provide when calling the `loginUser` API.
   */
  (
    type: 'messenger',
    command: 'loginUser',
    callback: (fn: (newJwtForUser: string) => void) => void
  ): void

  /**
   * Your app may have a logout function that brings users back to a login screen. In this case, revert the messaging
   * Web Widget to a pre-login state by calling the `logoutUser` API.
   *
   * After a user is logged out, all conversation tags and conversation fields data is cleared.
   * Use the Conversation Fields and Tags API again to apply conversation fields and tags data to a new conversation.
   */
  (type: 'messenger', command: 'logoutUser'): void

  /**
   * Displays the widget on the host page in the state it was in before it was hidden.
   * The widget is displayed by default on page load.
   * You don't need to call `show` to display the widget unless you use `hide`.
   */
  (type: 'messenger', command: 'show'): void

  /**
   * Hides all parts of the widget from the page. You can invoke it before or after page load.
   */
  (type: 'messenger', command: 'hide'): void

  /**
   * Opens the messaging Web Widget.
   */
  (type: 'messenger', command: 'open'): void

  /**
   * Closes the messaging Web Widget.
   */
  (type: 'messenger', command: 'close'): void

  /**
   * Executes a callback when the messaging Web Widget opens.
   */
  (type: 'messenger:on', event: 'open', callback: () => void): void

  /**
   * Executes a callback when the messaging Web Widget closes.
   */
  (type: 'messenger:on', event: 'close', callback: () => void): void

  /**
   * Executes a callback when the number of unread messages changes.
   */
  (
    type: 'messenger:on',
    event: 'unreadMessages',
    callback: (unreadMessageCount: number) => void
  ): void

  /**
   * Sets the locale of the messaging Web Widget.
   * It overrides the messaging Web Widget's default behavior of matching the same language an
   * end user has set in their web browser.
   *
   * The command takes a locale string as an argument.
   * For a list of supported locales and associated codes, use the following Zendesk public REST API endpoint:
   * https://support.zendesk.com/api/v2/locales/public.json.
   *
   * **Note:** This code should be placed immediately after the messaging Web Widget code snippet.
   */
  (type: 'messenger:set', setting: 'locale', newLocale: string): void

  /**
   * Sets the CSS property z-index on all the iframes for the messaging Web Widget.
   *
   * When two elements overlap, the z-index values of the elements determine which one covers the other.
   * An element with a greater z-index value covers an element with a smaller one.
   *
   * By default, all iframes in the messaging Web Widget have a z-index value of `999999`.
   */
  (type: 'messenger:set', setting: 'zIndex', newZIndex: number): void

  /**
   * The messaging Web Widget uses a mixture of cookies as well as local and session storage in order to function.
   *
   * If the end user has opted out of cookies, you can use the command below to let the messaging
   * Web Widget know that it is unable to use any of these storage options.
   *
   * Currently, disabling cookies will result in the messaging Web Widget being hidden from the end user
   * and all values in local and session storage being deleted.
   */
  (type: 'messenger:set', setting: 'cookies', isEnabled: boolean): void

  /**
   * Allows values for conversation fields to be set in the client to add contextual data about the conversation.
   * To learn more about Messaging Metadata, see
   * [Introduction to Messaging Metadata](https://support.zendesk.com/hc/en-us/articles/5868905484442).
   *
   * Conversation fields must first be created as custom ticket fields and configured to allow their values to be
   * set by end users in Admin Center. To use conversation fields, see
   * [Using Messaging Metadata with the Zendesk Web Widget and SDKs](https://support.zendesk.com/hc/en-us/articles/5658339908378).
   *
   * **Note**: Conversation fields aren't immediately associated with a conversation when the API is called.
   * It'll only be applied when end users start a conversation or send a message in an existing conversation
   * from the page it's called from.
   *
   * [System ticket fields](https://support.zendesk.com/hc/en-us/articles/4408886739098), such as the Priority field,
   * are not supported.
   *
   * Conversation fields are cleared when the
   * [authentication API](https://developer.zendesk.com/api-reference/widget-messaging/web/authentication/#logout)
   * to sign-out is called. The `conversationFields` API needs to be called again to apply field metadata to a fresh
   * conversation.
   */
  (
    type: 'messenger:set',
    setting: 'conversationFields',
    conversationFields: {
      id: string
      value: string | number | boolean
    }[]
  ): void

  /**
   * Allows custom conversation tags to be set in the client to add contextual data about the conversation.
   * To learn more about Messaging Metadata, see
   * [Introduction to Messaging Metadata](https://support.zendesk.com/hc/en-us/articles/5868905484442).
   *
   * Conversation tags do not need any prerequisite steps before the API can be used. To use conversation tags, see
   * [Using Messaging Metadata with the Zendesk Web Widget and SDKs](https://support.zendesk.com/hc/en-us/articles/5658339908378).
   *
   * **Note:** Conversation tags aren't immediately associated with a conversation when the API is called.
   * It'll only be applied when end users start a conversation or send a message in an existing conversation
   * from the page it's called from.
   *
   * Conversation tags are cleared when the
   * [authentication API](https://developer.zendesk.com/api-reference/widget-messaging/web/authentication/#logout)
   * to sign-out is called. The `conversationTags` API needs to be called again to apply tag metadata to a fresh
   * conversation.
   */
  (type: 'messenger:set', setting: 'conversationTags', conversationTags: string[]): void
}

interface ZendeskUIString {
  [locale: string]: string
}

interface ZendeskField {
  id: string | number
  prefill: ZendeskUIString
}

interface ZendeskSettings {
  analytics?: boolean
  cookies?: boolean
  errorReporting?: boolean
  webWidget?: {
    answerBot?: {
      avatar?: {
        url: string
        name: ZendeskUIString
      }
      suppress?: boolean
      title?: ZendeskUIString
      contactOnlyAfterQuery?: boolean
      search?: {
        labels: string[]
      }
    }
    authenticate?: {
      chat?: {
        jwtFn: (callback: (jwtToken: string) => void) => void
      }
      jwtFn?: (callback: (jwtToken: string) => void) => void
    }
    contactForm?: {
      attachments?: boolean
      fields?: ZendeskField[]
      selectTicketForm?: ZendeskUIString
      subject?: boolean
      suppress?: boolean
      title?: ZendeskUIString
      ticketForms?: Array<{ id: number; fields?: ZendeskField[] }>
    }
    contactOptions?: {
      enabled?: boolean
      contactButton?: ZendeskUIString
      contactFormLabel?: ZendeskUIString
      chatLabelOnline?: ZendeskUIString
      chatLabelOffline?: ZendeskUIString
    }
    chat?: {
      concierge?: {
        avatarPath: string
        name: string
        title: ZendeskUIString
      }
      departments?: {
        enabled: string[]
        select?: string
      }
      hideWhenOffline?: boolean
      menuOptions?: {
        emailTranscript?: boolean
      }
      notifications?: {
        mobile?: {
          disable?: boolean
        }
      }
      offlineForm?: {
        greeting?: ZendeskUIString
      }
      prechatForm?: {
        greeting?: ZendeskUIString
        departmentLabel?: ZendeskUIString
      }
      profileCard?: {
        avatar?: boolean
        rating?: boolean
        title?: boolean
      }
      suppress?: boolean
      tags?: string
      title?: ZendeskUIString
    }
    color?: {
      theme?: string
      launcher?: string
      launcherText?: string
      button?: string
      resultLists?: string
      header?: string
      articleLinks?: string
    }
    helpCenter?: {
      messageButton?: ZendeskUIString
      originalArticleButton?: boolean
      searchPlaceholder?: ZendeskUIString
      suppress?: boolean
      title?: ZendeskUIString
      chatButton?: ZendeskUIString
      filter?: {
        category?: string
        section?: string
        label_names?: string
      }
    }
    launcher?: {
      label?: ZendeskUIString
      mobile?: {
        labelVisible?: boolean
      }
      badge?: {
        label?: ZendeskUIString
        image?: string
        layout?: 'image_right' | 'image_left' | 'image_only' | 'text_only'
      }
      chatLabel?: ZendeskUIString
    }
    navigation?: {
      popoutButton?: {
        enabled?: boolean
      }
    }
    offset?: {
      horizontal?: string
      vertical?: string
      mobile?: {
        horizontal?: string
        vertical?: string
      }
    }
    position?: {
      horizontal: 'left' | 'right'
      vertical: 'top' | 'bottom'
    }
    talk?: {
      preferredName?: string
      suppress?: boolean
      title?: ZendeskUIString
    }
    zIndex?: number
  }
}

declare global {
  interface Window {
    zESettings?: ZendeskSettings
    zE?: ZendeskWidget
  }
}

const ZendeskChatContext = createContext<ZendeskChatContextType | undefined>(undefined)

export const ZendeskChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [errorLoading, setErrorLoading] = useState<boolean | null>(null)
  const chatEnabled = useFeatureFlag('zendeskChatbot')
  const router = useRouter()
  const { user } = useUser()
  const { status } = useSession()
  const [hasUserLoggedIn, setHasUserLoggedIn] = useState(false)
  const cookies = parseCookies()
  const session = useSession()

  const {
    publicRuntimeConfig: { zendeskToken },
  } = getConfig()

  const logoutUser = useCallback(async () => {
    console.log('>> Logging out user from Zendesk Chat')
    window?.zE?.('messenger', 'logoutUser')
    setHasUserLoggedIn(false)

    destroyCookie(null, 'zendeskChatToken', {
      path: '/',
      domain: cookieDomain,
    })
  }, [])

  const setChatSessionMetadata = useCallback(() => {
    window?.zE?.('messenger:set', 'conversationTags', [
      `role:${user?.role}`,
      `language:${user?.languageCode}`,
      `user_email:${user?.email}`,
      `user_id:${user?.id}`,
      `is_sso:${user?.isSso}`,
      `account_name:${user?.account?.name}`,
      `account_id:${user?.accountId}`,
      `url:${window.location.href}`,
      `environment:${process.env.NEXT_PUBLIC_ENV || 'unknown'}`,
    ])
  }, [user])

  const getJWTToken = useCallback(async () => {
    let jwtToken = null

    try {
      if (cookies.zendeskChatToken) {
        jwtToken = cookies.zendeskChatToken
      } else {
        const { data } = await axios.get('/api/zendesk/token')
        jwtToken = data?.token

        setCookie(null, 'zendeskChatToken', jwtToken, {
          maxAge: 60 * 60 * 24 * 4, // 4 days
          path: '/',
          domain: cookieDomain,
        })
      }
    } catch (error) {
      destroyCookie(null, 'zendeskChatToken', {
        path: '/',
        domain: cookieDomain,
      })

      console.error('Error getting Zendesk token:', error)
    }

    return jwtToken
  }, [cookies.zendeskChatToken])

  const loginUser = useCallback(async () => {
    const jwtToken = await getJWTToken()

    if (!jwtToken) {
      console.warn('No JWT token found. User will not be logged in to Zendesk Chat')
      return
    }

    window?.zE?.('messenger', 'loginUser', async (callback: (token: string) => void) => {
      setChatSessionMetadata()
      callback(jwtToken)
      setHasUserLoggedIn(true)
    })
  }, [setChatSessionMetadata, getJWTToken])

  useEffect(() => {
    const handleRouteChange = () => {
      setChatSessionMetadata()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    handleRouteChange()

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router, user, setChatSessionMetadata])

  useEffect(() => {
    if (status === 'authenticated') {
      loginUser()
    }

    if (status === 'unauthenticated') {
      logoutUser()
    }
  }, [session, status, loginUser, logoutUser, hasUserLoggedIn])

  useEffect(() => {
    const injectCustomStyles = (iframe: HTMLIFrameElement) => {
      iframe.style.width = '40px'
      iframe.style.height = '40px'
      const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document
      if (iframeDocument) {
        const existingStyle = iframeDocument.querySelector('#customStyles')
        if (!existingStyle) {
          const style = iframeDocument.createElement('style')
          style.id = 'customStyles'
          style.innerHTML = `
          div[shape="circle"] {
            width: 40px;
            height: 40px;
            }
            `
          iframeDocument.head.appendChild(style)
        }
      }
    }

    const observer = new MutationObserver((mutations, observerInstance) => {
      let changesApplied = false

      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (
            node instanceof HTMLIFrameElement &&
            node.getAttribute('title')?.includes('Button to launch messaging window')
          ) {
            injectCustomStyles(node)
            changesApplied = true
          }
          if (
            node instanceof HTMLIFrameElement &&
            node.getAttribute('title')?.includes('Number of unread messages')
          ) {
            node.style.bottom = '36px'
          }
        })
      })
      // adjust the zendesk default "Need any help?" chat bubble position
      const matchingTitleElements = document.querySelectorAll(
        '[data-product="web_widget"] + div > div > div'
      )
      matchingTitleElements.forEach((element) => {
        if (element instanceof HTMLDivElement && element.style.bottom === '80px') {
          element.style.bottom = '60px'
        }
      })

      // Disconnect the observer if changes have been successfully applied
      if (errorLoading || changesApplied) {
        observerInstance.disconnect()
      }
    })

    const config = { childList: true, subtree: true }
    const targetNode = document.querySelector('[data-product="web_widget"]') || document.body

    observer.observe(targetNode, config)

    return () => {
      observer.disconnect()
    }
  }, [errorLoading])

  return (
    <>
      {zendeskToken && chatEnabled && (
        <Script
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${zendeskToken}`}
          strategy="afterInteractive"
          onError={() => {
            setErrorLoading(true)
            console.warn('Error loading Zendesk chat widget. No chat support available')
          }}
          onLoad={async () => {
            setErrorLoading(false)
            console.log('Zendesk Chat Loaded')

            loginUser()

            const isPuppeteer = /HeadlessChrome/.test(navigator.userAgent)
            if (isPuppeteer) {
              window?.zE?.('messenger', 'hide')
              console.log('Zendesk Chat Hidden')
            }
          }}
        />
      )}
      <ZendeskChatContext.Provider value={{ zendeskChatLoaded: !errorLoading }}>
        {children}
      </ZendeskChatContext.Provider>
    </>
  )
}
